import React from "react"
import Seo from "../components/seo"
import Hero, { HeroContent } from "../components/hero"
import LayoutDefault from "../layouts/default"

import { Row, Content, Article } from "../components/article"

const ServicesPage = () => {
  const breadcrumbList = [
    { to: "/", name: "Главная" },
    { to: "/services", name: "Услуги" },
  ]

  const content = `
    <p>Разрабатываем сайты для бизнеса, оформляем социальные сети. Основная цель нашей работы - окупить инвестиции клиента!</p>
    <div class="grid">
      <div class="grid__column grid__column-8">
        <h4>Разработка</h4>
        <ul>
          <li>Создание Landing Page</li>
          <li>Разработка многостраничных сайтов</li>
          <li>Адаптивная верстка</li>
        </ul>
      </div>
      <div class="grid__column grid__column-8">
        <h4>Дизайн</h4>
        <ul>
          <li>Дизайн сайтов</li>
          <li>Редизайн сайтов</li>
          <li>Оформление социальных сетей</li>
        </ul>
      </div>
      <div class="grid__column grid__column-8">
        <h4>Продвижение</h4>
        <ul>
          <li>SMM продвижение</li>
          <li>SEO продвижение</li>
        </ul>
      </div>
    </div>
    <p>Мы помогаем компаниям увеличивать доход, расширять узнаваемость бренда и делать их клиентов счастливыми, создавая уникальные веб-проекты и графические проекты.</p>
  `

  return (
    <LayoutDefault>
      <Seo
        title="Услуги"
        description="Услуги"
        url="/services"
      />
      <Hero breadcrumbs={breadcrumbList} backLink="/">
        <HeroContent>
          <h1 className="heading">Наши услуги</h1>
        </HeroContent>
      </Hero>
      <Row>
        <Content>
          <Article dangerouslySetInnerHTML={{ __html: content }}/>
        </Content>
      </Row>
    </LayoutDefault>
  )
}

export default ServicesPage